import React from "react"

import Header from "../components/header"
import Footer from "../components/footer"

import SEO from "../components/seo"

import Tafelmalerei1 from "../components/tafelmalerei1"
import Tafelmalerei2 from "../components/tafelmalerei2"
import Tafelmalerei3 from "../components/tafelmalerei3"
import Tafelmalerei4 from "../components/tafelmalerei4"

import "./index.css"

export default function TafelmaleiPage() {
  return (
    <>
      <SEO
        description="restaura ratisbona, Ihr Team für Restaurierungen. Tafelmalerei"
        title="restaura ratisbona, Ihr Team für Restaurierungen. Tafelmalerei"
        lang="de"
      />
      <Header />
      <section>
        <h1 className="indexHeadline">Tafelmalerei</h1>
        <h2 className="referenceH2">
          Restaurierung von 13 Tafelmalereien, Kath. Kirche St. Petrus und
          Paulus, Beuren bei Isny
        </h2>
        <h3 className="referenceH3">Konservierung, Reinigung, Retusche</h3>
        <div className="referencePageDiv">
          <div className="referenceImages">
          <label htmlFor="Tafelmalerei1">
              <Tafelmalerei1 id="Tafelmalerei1" />
              Teil des Tafelbilderensembles <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Tafelmalerei2">
              <Tafelmalerei2 id="Tafelmalerei2" />
              Zustand vor der Restaurierung <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Tafelmalerei3">
              <Tafelmalerei3 id="Tafelmalerei3" />
              Konservierung hochstehender Malschichtschollen mit Hausblasenleim
              mittels Japanpapierfacing <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Tafelmalerei4">
              <Tafelmalerei4 id="Tafelmalerei4" />
              Abnahme der Japanpapiere nach dem Festigungsvorgang und Reinigung
              der Malschichtoberfläche <br />
              (Foto: Astrid Mendes)
            </label>
          </div>
          <div className="referenceTextDiv">
            <h4 className="referenceH4">Geschichte und Bedeutung</h4>
            <p className="referenceText">
              Die Segmente eines ehemaligen Hochaltars aus Oberdorf wurden 1855
              von der Gemeinde Beuren aufgekauft. Dort teilte man die
              Bestandteile auf zwei Seitenaltäre auf. Besonderes Augenmerk
              verdienen die 13 Tafelmalereien der Altäre, die Hans Schäufelin um
              1517 schuf. Bei dem Künstler handelt es sich um einen ehemaligen
              Mitarbeiter der Albrecht Dürer-Werkstatt, weshalb man an
              Schäufelins Pinselduktus Dürers Einfluss erkennt. Im Jahr 1515
              eröffnete Schäufelin in Nördlingen einen eigenen Werkstattbetrieb.
              Kurz darauf schuf er um 1515/16 den berühmten Christgadener Altar
              (Bayerische Staatgemäldesammlung Inv. Nr. WAF 922), an dem man
              einige Parallelen zu dem Werk in Beuren erkennen kann (Publikation
              von Astrid Mendes in Arbeit).
            </p>

            <h4 className="referenceH4">Erhaltungszustand / Befundsituation</h4>
            <p className="referenceText">
              Der Kunstmaler Mauerer, der den Altar von der Oberdorfer Gemeinde
              für 100 Gulden kaufte, nahm von 1850 bis 1858 umfangreiche
              Festigungs- und Ergänzungsarbeiten an den Tafeln vor. 1933 wurden
              die Übermalungen an den Schäufelinaltären wieder abgenommen und
              die Fehlstellen, so hieß es in den Quellen, mit der ursprünglichen
              Farbe geschlossen. 1987 kam es erneut zu partiellen Übermalungen
              an den Hintergründen der Darstellungen. Zudem wurden die Tafeln
              mit Überzügen versehen, die vermutlich Oberflächenspannungen
              verursachten. Diese drei Restaurierungen haben das Werk erheblich
              verändert und Schäden ausgelöst, sodass Schäufelins originale
              Handschrift nur noch in den Details zu erkennen ist.
            </p>

            <h4 className="referenceH4">Restauratorische Zielstellung</h4>
            <p className="referenceText">
              Im Zentrum der Tafelmalereirestaurierung stand die Konservierung
              gelockerter Malschicht. Diese wurde mit warmen Hausenblasenleim
              und einem Japanpapierfacing vorgenommen.
            </p>

            <h4 className="referenceH4">Maßnahmen</h4>
            <p className="referenceText">
              Im Anschluss an die Konservierung erfolgte eine Reinigung der
              Malschicht. Die ästhetischen Maßnahmen, wie Retuschen und
              Schließung der Fehlstellen, beschränkten sich auf nur wenige
              störende Bereiche, beispielsweise am Gewand der Hl. Barbara und
              der Hl. Katharina, wo unsachgemäße, nicht farbgetreue Retuschen
              aus früheren Restaurierungen minimale Korrekturen erforderten.
            </p>

            <h4 className="referenceH4">Auftraggeber</h4>
            <p className="referenceText">
              Kath. Kirchengemeinde von Beuren Architekt: Junker + Partner,
              Dipl.-Ing. Hartmut Junker, Baubetreuung Dipl.-Ing. Ulrich
              Volkmann.
            </p>

            <h4 className="referenceH4">Fachbehörden</h4>
            <p className="referenceText">
              Restaurator Jochen Ansel, Landesamt für Denkmalpflege
              Baden-Württemberg, Esslingen a. Neckar.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
